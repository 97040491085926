@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap");
@import "./styles/questionPreview";

:root {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main {
  height: 100vh;
  width: 100vw;
}

.iframe-container {
  height: 600px;
  width: 100%;
  border: none;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   // background: #fff;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

a {
  text-decoration: none;
}

.quill-container {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    // border: 1px solid rgba(0, 0, 0, 0.87);
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // border: 1px solid rgba(0, 0, 0, 0.87);
    .ql-editor {
      min-height: 150px;
    }
  }
  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label:not(.ql-active) {
          &::before {
            content: "16px";
          }
        }
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value);
          }
        }
      }
    }
  }
  .ql-container {
    font-size: 1rem;
    .ql-size-12px {
      font-size: 0.75rem;
    }
    .ql-size-14px {
      font-size: 0.875rem;
    }
    .ql-size-16px {
      font-size: 1rem;
    }
    .ql-size-18px {
      font-size: 1.125rem;
    }
    .ql-size-20px {
      font-size: 1.25rem;
    }
    .ql-size-24px {
      font-size: 1.5rem;
    }
    .ql-size-28px {
      font-size: 1.75rem;
    }
    .ql-size-36px {
      font-size: 2.25rem;
    }
  }
}

.micro-image {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  object-fit: contain;
  border-radius: 8px;
}

.sidebar {
  height: 100vh;
  width: 240px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .sider-menu {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 12px;
    color: #637e85;
    cursor: pointer;
    // .side-bar-icon {
    //   path {
    //     fill: #ffffff;
    //   }
    // }
  }
  .active.sider-menu {
    background-color: #e3f1f4;
    color: #327091;
    .MuiTypography-root {
      font-weight: 500; 
    }
    // .side-bar-icon {
    //   .side-bar-icon-rect {
    //     fill: rgba(255, 255, 255, 0.1);
    //   }
    //   path {
    //     fill: #327091;
    //   }
    // }
  }
}

.rearrange-section {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 12px;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  .shuffle-handler {
    margin-right: 15px;
  }
}

.dot-flashing-wrapper {
  background: #ffffff;
  padding: 19px 0px 8px 31px;
  border-radius: 8px 0px 8px 8px;
  width: 75px;
  height: 58px;
  .dot-flashing {
    margin-top: 8px;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.1s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -12.5px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 12.5px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: #4b5563;
    color: #4b5563;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #4b5563;
    }
    50%,
    100% {
      background-color: #f3f4f6;
    }
  }
}

.google-fit-ul {
  padding-left: 2rem;
  font-size: 1.125rem;
  li {
    margin-top: 1rem;
  }
}
